.prsnt-table {
  $self: #{&};

  overflow: hidden;
  border-radius: $border-radius;
  background-color: #fff;

  table {
    table-layout: auto;
    width: 100%;
    border-collapse: collapse;
    font-weight: normal;
  }

  &-cell {
    padding: 0.5em 0;
    padding: 1rem 1.5rem;
    position: relative;
  }

  &-row {
    position: relative;
    border-bottom: 1px solid $border-color;
    transition: 0.1s;

    &:last-child {
      border-bottom: none;
    }
  }

  &-thead {
    text-align: left;
    border-bottom: 1px solid $primary-color;

    #{$self}-cell {
      font-weight: 500;
      padding: 1.5rem 1.5rem;
      white-space: nowrap;
    }
  }

  &--has-title {
    #{$self}-thead tr {
      border-top: 1px solid $border-color;
    }
  }

  &--has-footer {
    #{$self}-row:last-child {
      border-bottom: 1px solid $border-color;
    }
  }

  &--bordered {
    border: 1px solid $border-color;

    #{$self}-cell:not(:last-child) {
      border-right: 1px solid $border-color;
    }
  }

  &--stripped {
    #{$self}-row:nth-of-type(odd) {
      background-color: rgba(0, 0, 0, 0.031);
    }
  }

  &--stripped-even {
    #{$self}-row:nth-of-type(even) {
      background-color: rgba(0, 0, 0, 0.031);
    }
  }

  &--black-header {
    #{$self}-thead {
      background-color: rgba(0, 0, 0, 0.843);
      color: white;
    }
  }

  &--dense {
    #{$self}-thead #{$self}-cell {
      padding: 1rem 1rem;
    }
    #{$self}-cell {
      padding: 0.5rem 1rem;
    }
  }

  &--highlight-hover {
    #{$self}-row:hover {
      background-color: rgba(0, 0, 0, 0.063);
    }
  }

  &--elevated {
    box-shadow: $box-shadow;
  }
}

.blocked-user {
  background-color: #f7baba !important;
}
